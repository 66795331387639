img.payment-success-image {
  /* width: -webkit-fill-available; */
  max-width: 400px;
  width: 100%;
}

.common-primary-button {
  align-items: center;
  padding: 12px 20px;
  background: #1a1a1a;
  border: 2px solid #ffffff;
  border-radius: 4px;
  width: 100%;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  font-family: "Quicksand", sans-serif;
  color: #ffffff;
  transition: all 0.5s ease;
}
.common-primary-button:hover {
  background-color: #141f80;
  transition: all 0.5s ease;
}

/* calendly */

.calendly-badge-widget {
  margin: 0px;
}
.calendly-badge-widget .calendly-badge-content:hover {
  background : #e3ebff !important;
}
.calendly-badge-widget .calendly-badge-content {
  display: inline-block !important;
  box-shadow: none !important;
  font-family: "Quicksand", sans-serif !important;
  color: #141f80 !important;
  transition: all 0.3s ease;
  border: 1px solid #141f80 !important;
  border-radius: 5px !important;
  background: white !important;
  position: relative;
  /* width: 40px !important; */
  padding-top: 10px  !important;
  padding-bottom: 10px  !important;
  padding-right: 15px  !important;
  padding-left: 40px  !important;
  text-transform: uppercase;
  box-shadow: none !important;
}  
.token-expired {
  font-size: 18px;
  font-weight: bold;
  color: #c85555;
}

.common-primary-a-tag {
  align-items: center;
  padding: 12px 20px;
  background: #1a1a1a;
  border: 2px solid #ffffff;
  border-radius: 4px !important;
  width: 100%;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  font-family: "Quicksand", sans-serif;
  color: #ffffff;
  transition: all 0.5s ease;
}

.common-primary-a-tag:hover {
  color: black;
  background: white;
  border: 1px solid black;
}

.success-pop-left {
  position: absolute;
  left: 0;
  top: 27%;
}

.success-pop-right {
  position: absolute;
  right: 0;
  top: 27%;
  transform: scaleX(-1);
}

.link-label {
  font-weight: 700;
  padding-right: 30px;
  font-size: 18px;
  position: relative;
  text-transform: uppercase;
  line-height: 22px;
  font-family: "Quicksand", sans-serif;
  color: #1a1a1a;
}
